import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React, { useEffect, useState } from "react"
import { createUseStyles } from "react-jss"
import GalleryModal from "src/components/gallerymodal"
import Quote from "src/components/Quote"
import Layout from "../components/layout"
import SEO from "../components/seo"

const useStyles = createUseStyles({
  content: {
    width: `60%`,
    margin: `auto`,
    textAlign: `center`,
  },
  h4: {
    fontSize: `calc(0.5vh + 1rem)`,
    fontStyle: `italic`,
    fontWeight: `normal`,
    marginBottom: `0.5rem`,
  },
  threeCol: {
    width: `100%`,
    marginBottom: `1em`,
  },
  threeColContainer: {
    display: `flex`,
    margin: `0px -30%`,
  },
  threeColImage: {
    margin: `1em 0`,
  },
  ul: {
    display: `flex`,
    flexWrap: `wrap`,
    justifyContent: `center`,
    listStyleType: `disc`,
    marginBottom: `1em`,
  },
  li: {
    margin: `0px 20px`,
  },
  "@media (max-width: 850px)": {
    content: {
      width: `100%`,
    },
    threeColContainer: {
      flexDirection: `column`,
      margin: `0px 0px`,
    },
  },
})

interface Props {
  data: GatsbyTypes.CabinQuery
}

const AboutPage = ({ data }: Props) => {
  const classes = useStyles()
  const [height, setHeight] = useState<number>(0)
  const [currId, setCurrId] = useState<string | null | undefined>()

  useEffect(() => {
    console.log(`currId: ${currId}`)
    if (currId) {
      document.documentElement.style.overflowY = "hidden"
    } else {
      document.documentElement.style.overflowY = "scroll"
    }

    return () => {
      document.documentElement.style.overflowY = "scroll"
    }
  }, [currId])

  const next = () =>
    setCurrId(id => {
      const currIdx = images.findIndex(
        image => image != null && image.id === id
      )
      if (currIdx !== -1) {
        const nextIdx = (currIdx + 1) % images.length
        const nextImg = images[nextIdx]
        if (nextImg != null) {
          return nextImg.id
        }
      }
    })

  const prev = () =>
    setCurrId(id => {
      const currIdx = images.findIndex(
        image => image != null && image.id === id
      )
      if (currIdx !== -1) {
        const prevIdx = (currIdx - 1 + images.length) % images.length
        const prevImg = images[prevIdx]
        if (prevImg != null) return prevImg.id
      }
    })

  const downHandler = ({ key }: KeyboardEvent) => {
    if (key === "Escape") {
      setCurrId(undefined)
    }
    if (key === "ArrowRight") {
      next()
    }
    if (key === "ArrowLeft") {
      prev()
    }
  }

  useEffect(() => {
    window.addEventListener("keydown", downHandler)
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener("keydown", downHandler)
    }
  }, [])

  const amenities = data.contentfulAmenities?.amenities

  const images =
    data.contentfulFloorPlan?.sections?.map(section => section?.image) ?? []
  const currImg = images.find(image => image != null && image.id === currId)

  return (
    <Layout setHeaderHeight={setHeight}>
      <SEO title="The Cabin" />
      {currId != null && (
        <GalleryModal
          key={currId}
          currImg={currImg}
          close={() => {
            setCurrId(undefined)
          }}
          prev={prev}
          next={next}
        />
      )}
      <main
        style={{
          // height: `100vh`,
          marginTop: `${height}px`,
          padding: `6.6vmax 4vw 4vw`,
          boxSizing: "border-box",
        }}
      >
        <div className={classes.content}>
          <h1 style={{ marginBottom: 17 }}>The Cabin</h1>
          {data.contentfulQuote != null && (
            <Quote data={data.contentfulQuote} />
          )}

          <p style={{ fontSize: 20, lineHeight: 1.6, marginBottom: 17 }}>
            {data.contentfulCabinInfo?.description?.description}
          </p>
          <h3
            style={{
              margin: `1.5em 0px 0.5rem`,
              fontSize: `calc((2.2 - 1) * 1.2vh + 1rem)`,
            }}
          >
            Floor Plan
          </h3>
          <div className={classes.threeColContainer}>
            {data.contentfulFloorPlan?.sections?.map(section => {
              return (
                <div className={classes.threeCol}>
                  <h4 className={classes.h4}>{section?.floor}</h4>
                  <div
                    className={classes.threeColImage}
                    onClick={
                      section?.image?.id != null
                        ? () => setCurrId(section?.image?.id)
                        : undefined
                    }
                    style={{
                      cursor: `zoom-in`,
                    }}
                    tabIndex={0}
                  >
                    {section?.image?.gatsbyImageData != null && (
                      <GatsbyImage
                        image={section.image.gatsbyImageData}
                        imgStyle={{ objectFit: "contain" }}
                        style={{
                          height: "400px",
                          // width: "100%",
                          // filter: `brightness(0.7) saturate(1.5)`,
                        }}
                      />
                    )}
                  </div>
                  <ul>
                    {section?.description?.description?.split("\n").map(li => (
                      <li>{li}</li>
                    ))}
                  </ul>
                </div>
              )
            })}
          </div>
          <h3
            style={{
              margin: `1em 0px 0.5rem`,
              fontSize: `calc((2.2 - 1) * 1.2vh + 1rem)`,
            }}
          >
            Amenities
          </h3>
          {amenities?.map(amenity => {
            if (amenity == null) return null
            return (
              <>
                <h4 className={classes.h4}>{amenity.location}</h4>
                {amenity.childrenContentfulAmenitiesSectionListTextNode !=
                  null && (
                  <ul className={classes.ul}>
                    {amenity.childrenContentfulAmenitiesSectionListTextNode[0]?.list
                      ?.split("\n")
                      .map(li => {
                        return <li className={classes.li}>{li}</li>
                      })}
                  </ul>
                )}
              </>
            )
          })}

          <h3
            style={{
              margin: `1.5em 0px 0.5rem`,
              fontSize: `calc((2.2 - 1) * 1.2vh + 1rem)`,
            }}
          >
            Location
          </h3>
          <p style={{ fontSize: 18, lineHeight: 1.6, marginBottom: 17 }}>
            The cabin is in a remote, quiet location. The roads are
            well-maintained and can be accessed year round!
            <br />
            Grocery stores are about half an hour away so please plan
            accordingly. The Saddlebrook Grill restaurant by the entrance of
            Leatherwood is open on weekends (Friday dinnertime to Sunday
            lunchtime).
          </p>
        </div>
      </main>
    </Layout>
  )
}

export default AboutPage

export const pageQuery = graphql`
  query Cabin {
    contentfulQuote(pageId: { eq: "cabin" }) {
      ...Quote
    }
    contentfulAmenities {
      id
      amenities {
        childrenContentfulAmenitiesSectionListTextNode {
          list
        }
        location
      }
    }
    contentfulCabinInfo {
      description {
        description
      }
    }
    contentfulFloorPlan {
      sections {
        id
        description {
          description
        }
        floor
        image {
          id
          gatsbyImageData
        }
      }
    }
  }
`
